<script setup>
import { ref, computed, watch } from 'vue';
import DictionaryTable from '@/components/dictionaries/DictionaryTable.vue';
import { navItems } from '@/helpers/navigationPanelHelper.js';
import { useNavigationPanelStore } from '@/stores/navigationPanel';
import dictionariesViewConfig from '@/data/dictionariesViewConfig.js';

const activeTab = ref(0);
const searchBox = ref('');
const searchValues = ref([]);
const alreadyLoadedTabs = ref([]);

for (let i = 0; i < dictionariesViewConfig.length; i++) {
  searchValues.value.push('');
  alreadyLoadedTabs.value.push(i === 0);
}

const searchBarPlaceholder = computed(() => {
  const el = dictionariesViewConfig.find((x) => x.id === activeTab.value);

  if (el == undefined) return '';
  else return el.searchPlaceholder;
});

watch(activeTab, () => {
  searchBox.value = '';

  if (searchValues.value.length <= activeTab.value) return;

  searchValues.value[activeTab.value] = '';
  alreadyLoadedTabs.value[activeTab.value] = true;
});

function search() {
  if (searchValues.value.length <= activeTab.value) return;

  searchValues.value[activeTab.value] = searchBox.value;
}

// created
useNavigationPanelStore().setNavigationPanelItems(navItems.DICTIONARIES);
</script>

<template>
  <div>
    <div class="row">
      <div class="ibox col-xl-2 col-lg-auto">
        <div class="ibox-content">
          <ul class="list-group elements-list">
            <li
              v-for="dictionary in dictionariesViewConfig"
              :key="dictionary.id"
              class="list-group-item"
            >
              <a
                class="nav-link"
                :class="{ active: activeTab === dictionary.id }"
                data-toggle="tab"
                :href="'#tab-' + dictionary.id"
                @click="activeTab = dictionary.id"
              >
                <i v-if="dictionary.iconClass" :class="dictionary.iconClass"></i>

                <img
                  v-else
                  :src="dictionary.iconSource"
                  :alt="dictionary.iconAltText"
                  width="13"
                  height="13"
                  style="margin-right: 6px"
                />

                <strong class="ml-2">{{ dictionary.header }}</strong>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="ibox col-xl-10">
        <div class="ibox-content">
          <h2>Słowniki</h2>
          <div class="input-group mt-4">
            <input
              type="text"
              :placeholder="searchBarPlaceholder"
              class="input form-control"
              v-model="searchBox"
              v-on:keyup.enter="search"
            />
            <span class="input-group-append">
              <button @click="search" type="button" class="btn btn btn-primary">
                <i class="fa fa-search"></i> Szukaj
              </button>
            </span>
          </div>
          <div class="element-detail-box">
            <div class="tab-content">
              <div
                v-for="dictionary in dictionariesViewConfig"
                :key="dictionary.id"
                :id="'tab-' + dictionary.id"
                class="tab-pane"
                :class="{ active: activeTab === dictionary.id }"
              >
                <div class="full-height-scroll">
                  <DictionaryTable
                    v-if="alreadyLoadedTabs[dictionary.id]"
                    :showHeader="false"
                    :showSearchBar="false"
                    :showBorder="false"
                    :searchFromOutside="searchValues[dictionary.id]"
                    :url="dictionary.url"
                    :columns="dictionary.columns"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
