<script setup>
import { ref, unref } from 'vue';
import { getDateWithoutTime } from '@/helpers/dateFormatHelper.js';
import { MODAL_ORDERS_TABLE } from '@/data/constants/modalConstants.js';
import { useModalTables } from '@/composables/modalTables.js';
import { createToaster } from '@meforma/vue-toaster';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import ModalBase from '@/components/utils/ModalBase.vue';
import ModalBody from '@/components/utils/ModalBody.vue';

const props = defineProps({
  initialOrders: { type: Array, default: () => new Array() }
});

const toaster = createToaster({ position: 'top-right', duration: 3000 });
const modalBase = ref(null);
const { modal, showModal } = useModalTables(modalBase);
const orders = ref(null);

function onModalCompleted(data, hideModal = true) {
  if (hideModal) {
    modalBase.value.hide();
  }

  switch (modal.value.type) {
    case MODAL_ORDERS_TABLE:
      data.canBeDeleted = true;
      if (orders.value.some((x) => x.id != null && x.id === data.id)) {
        toaster.Add('Wybrane zlecenie jest już na liście.', { type: 'warning' });
      } else {
        orders.value.push(data);
      }
      break;
  }
}

function deleteItemFromList(orderToDelete) {
  orders.value = orders.value.filter((x) => x.id != null && x.id !== orderToDelete.id);
}

// created
orders.value = unref(props.initialOrders);

defineExpose({ orders });
</script>

<template>
  <div class="ibox">
    <div class="ibox-title">
      <h5>Zlecenia, do których dodany zostanie protokół geodezji</h5>
      <div class="ibox-tools">
        <a
          class="btn btn-primary btn-xs"
          @click="
            showModal(MODAL_ORDERS_TABLE, {
              additionalProps: {
                initialUserFilter: { id_order_type: [2, 3] }
              }
            })
          "
          >+ Dodaj zlecenie do listy</a
        >
      </div>
    </div>
    <div class="ibox-content">
      <div v-if="isNotEmptyArray(orders)" class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th style="white-space: nowrap">Nr zlecenia</th>
              <th style="white-space: nowrap">Rodzaj zlecenia</th>
              <th style="white-space: nowrap">Zakres prac</th>
              <th style="white-space: nowrap">Elementy do likwidacji</th>
              <th style="white-space: nowrap">Gmina</th>
              <th style="white-space: nowrap">Region</th>
              <th style="white-space: nowrap">Lokalizacja</th>
              <th>Koordynator</th>
              <th style="white-space: nowrap">Wpłynęło do RADO</th>
              <th style="white-space: nowrap">Termin realizacji</th>
              <th style="white-space: nowrap; text-align: center">Status zlecenia</th>
              <th style="white-space: nowrap; text-align: center">Etap realizacji</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in orders" :key="order.id">
              <td style="white-space: nowrap">
                {{ order?.order_number ?? '-' }}
              </td>

              <td style="white-space: nowrap; text-align: center">
                <span
                  class="label label-primary"
                  :style="
                    order?.direction?.color != null
                      ? 'background-color:' + order.direction.color
                      : ''
                  "
                >
                  {{ order?.direction?.name ?? '-' }}</span
                >
              </td>

              <td style="white-space: nowrap">[zakres_prac]</td>
              <td style="white-space: nowrap">[elementy_do_likwidacji]</td>
              <td style="white-space: nowrap">{{ order?.gmina?.name ?? '-' }}</td>

              <td style="white-space: nowrap">
                {{ order?.region?.name ?? '-' }}
              </td>

              <td style="white-space: nowrap">
                {{ order?.address ?? '-' }}
              </td>

              <td style="white-space: nowrap">
                <span
                  v-if="
                    order?.coordinator?.firstName == null || order?.coordinator?.lastName == null
                  "
                >
                  -
                </span>
                <span v-else>
                  {{ order.coordinator.firstName + ' ' + order.coordinator.lastName }}
                </span>
              </td>

              <td style="white-space: nowrap">
                {{ order?.receive_date != null ? getDateWithoutTime(order.receive_date) : '-' }}
              </td>

              <td style="white-space: nowrap">
                {{ order?.deadline != null ? getDateWithoutTime(order.deadline) : '-' }}
              </td>

              <td style="white-space: nowrap; text-align: center">
                <span
                  class="label label-primary"
                  :style="
                    order?.order_status?.color != null
                      ? 'background-color:' + order.order_status.color
                      : ''
                  "
                >
                  {{ order?.order_status?.name ?? '-' }}</span
                >
              </td>

              <td style="white-space: nowrap; text-align: center">
                <span
                  class="label label-primary"
                  :style="
                    order?.implementation_phase?.color != null
                      ? 'background-color:' + order.implementation_phase.color
                      : ''
                  "
                >
                  {{ order?.implementation_phase?.name ?? '-' }}</span
                >
              </td>

              <td style="white-space: nowrap">
                <div class="float-right">
                  <button
                    v-if="order.canBeDeleted"
                    class="btn btn-outline btn-primary btn-xs"
                    type="button"
                    @click="deleteItemFromList(order)"
                    onclick="event.stopPropagation()"
                  >
                    <span>Usuń</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>
      <div></div>
    </div>

    <ModalBase ref="modalBase">
      <ModalBody>
        <template v-slot:title>
          {{ modal.title }}
        </template>
        <template v-slot:body>
          <component
            :is="modal.component"
            @on-completed="onModalCompleted"
            :key="modal.key"
            v-bind="modal.properties"
          ></component>
        </template>
      </ModalBody>
    </ModalBase>
  </div>
</template>

<style></style>
