<script setup>
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { navItems } from '@/helpers/navigationPanelHelper.js';
import FormErrorPanel from '@/components/utils/FormErrorPanel.vue';
import { MODAL_USER_TYPES_TABLE } from '@/data/constants/modalConstants.js';
import ModalInputField from '@/components/utils/ModalInputField.vue';
import UserColorsRadioButtons from '@/components/utils/UserColorsRadioButtons.vue';
import ModalTable from '@/components/utils/ModalTable.vue';
import { useNavigationPanelStore } from '@/stores/navigationPanel';
import { createToaster } from '@meforma/vue-toaster';
import { TOASTER_SERVER_ERROR_MSG } from '../../data/constants/toasterMessagesConstants';
import {
  WORKER_TYPE_ID,
  DRIVER_TYPE_ID,
  COORDINATOR_TYPE_ID
} from '../../data/constants/authConstants';

const RepositoryUsers = RepositoryFactory.get('users');

const modalTable = ref();
const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();

const formErrors = ref([]);
const isLoading = ref(false);
const user = ref({
  id: null,
  firstName: null,
  lastName: null,
  phone: null,
  email: null,
  type_id: null,
  password: null,
  active: null,
  color: null
});
const type = ref(null);
const initialPickedColor = ref(null);

const toaster = createToaster({ position: 'top-right' });

const loggedInUser = computed(() => authStore.loggedInUser);

const addMode = computed(() => {
  return isNaN(parseInt(route.params.id));
});

const idForEdit = computed(() => {
  return parseInt(route.params.id);
});

function submitForm(e) {
  e.preventDefault();

  if (addMode.value) {
    sendAddRequest();
  } else {
    sendEditRequest();
  }
}

async function getUserDataToEdit() {
  isLoading.value = true;
  user.value = await RepositoryUsers.getUserDataToEdit(idForEdit.value);

  if (Number.isInteger(user.value?.active)) {
    user.value.active = Boolean(Number(user.value.active));
  }

  type.value = user.value.type.name;
  initialPickedColor.value = user.value.color;
  isLoading.value = false;
}

async function sendAddRequest() {
  isLoading.value = true;

  if (user.value?.type_id !== WORKER_TYPE_ID && user.value?.type_id !== DRIVER_TYPE_ID) {
    delete user.value.color;
  }

  if (user.value?.type_id === COORDINATOR_TYPE_ID) {
    delete user.value.email;
  }

  var response = await RepositoryUsers.createUser(user.value);

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { duration: 3000, type: 'error' });
  } else {
    if (response) {
      formErrors.value = response;
    } else {
      toaster.show('Użytkownik został utworzony.', { duration: 3000, type: 'success' });
      router.push({ name: 'usersTable' });
    }
  }

  isLoading.value = false;
}

async function sendEditRequest() {
  isLoading.value = true;

  if (user.value?.type_id === WORKER_TYPE_ID || user.value?.type_id === DRIVER_TYPE_ID) {
    delete user.value.color;
  }

  delete user.value.password;

  if (user.value?.active === true) user.value.active = 1;
  else if (user.value?.active === false) user.value.active = 0;

  var response = await RepositoryUsers.updateUser(user.value);

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { duration: 3000, type: 'error' });
  } else {
    if (response) {
      formErrors.value = response;
    } else {
      if (user.value?.id != null && user.value.id === loggedInUser.value?.id) {
        authStore.changeLoggedInUserFirstName(user.value.firstName);
        authStore.changeLoggedInUserLastName(user.value.lastName);
      }

      toaster.show('Użytkownik został zaktualizowany.', { duration: 3000, type: 'success' });
      router.push({ name: 'usersTable' });
    }
  }

  isLoading.value = false;
}

function onModalCompleted(data) {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_USER_TYPES_TABLE:
      user.value.type_id = data?.id ?? null;
      type.value = data?.name ?? null;
      break;
  }
}

function onModalRejected() {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_USER_TYPES_TABLE:
      user.value.type_id = null;
      type.value = 'Wybierz typ użytkownika';
      break;
  }
}

// created
useNavigationPanelStore().setNavigationPanelItems(
  addMode.value ? navItems.USER_ADD : navItems.USER_EDIT
);

if (!addMode.value) {
  getUserDataToEdit();
}
</script>

<template>
  <div>
    <FormErrorPanel :errors="formErrors" />

    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5 v-if="addMode">Dodaj użytkownika</h5>
            <h5 v-else>Edytuj użytkownika</h5>
          </div>
          <div class="ibox-content" :class="{ 'sk-loading': isLoading }">
            <div class="sk-spinner sk-spinner-three-bounce">
              <div class="sk-bounce1"></div>
              <div class="sk-bounce2"></div>
              <div class="sk-bounce3"></div>
            </div>

            <form @submit="submitForm">
              <ModalInputField
                title="Typ użytkownika"
                :displayText="type"
                nullText="Wybierz typ użytkownika"
                :required="true"
                :disabled="!addMode"
                @showModal="modalTable.showModal(MODAL_USER_TYPES_TABLE)"
              />
              <div class="form-group">
                <label>Imię</label>
                <input
                  type="text"
                  placeholder="Wpisz imię"
                  v-model="user.firstName"
                  class="form-control"
                  required
                  minlength="1"
                  maxlength="100"
                />
              </div>
              <div class="form-group">
                <label>Nazwisko</label>
                <input
                  type="text"
                  placeholder="Wpisz nazwisko"
                  v-model="user.lastName"
                  class="form-control"
                  required
                  minlength="1"
                  maxlength="100"
                />
              </div>
              <div class="form-group">
                <label>Numer telefonu</label>
                <input
                  type="tel"
                  placeholder="Wpisz numer telefonu"
                  v-model="user.phone"
                  class="form-control"
                  minlength="1"
                  maxlength="30"
                  required
                />
              </div>

              <div v-if="user && user.type_id !== COORDINATOR_TYPE_ID" class="form-group">
                <label>E-mail</label>
                <input
                  type="email"
                  placeholder="Wpisz adres e-mail"
                  v-model="user.email"
                  class="form-control"
                  :disabled="!addMode"
                  maxlength="100"
                />
              </div>
              <div
                v-if="addMode && user && user.type_id !== COORDINATOR_TYPE_ID"
                class="form-group"
              >
                <label>Hasło</label>
                <input
                  autocomplete="new-password"
                  type="password"
                  placeholder="Wpisz hasło"
                  v-model="user.password"
                  class="form-control"
                  required
                  minlength="1"
                  maxlength="100"
                />
              </div>

              <div v-if="!addMode" class="form-group">
                <label>Aktywny</label>
                <div class="switch">
                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      id="example1"
                      v-model="user.active"
                    />
                    <label class="onoffswitch-label" for="example1">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div
                v-if="
                  user &&
                  (user.type_id === WORKER_TYPE_ID ||
                    user.type?.id === WORKER_TYPE_ID ||
                    user.type_id === DRIVER_TYPE_ID ||
                    user.type?.id === DRIVER_TYPE_ID)
                "
                class="form-group"
              >
                <label
                  >Kolor oznaczenia (wybierz z predefiniowanych albo skorzystaj z pełnej palety
                  kolorów)</label
                >
                <UserColorsRadioButtons
                  :initialPickedColor="initialPickedColor"
                  @onColorChanged="user.color = $event"
                />
              </div>

              <br />
              <div class="row">
                <div class="col-12">
                  <button class="float-right btn btn-sm btn-primary m-t-n-xs" type="submit">
                    <strong v-if="addMode">Dodaj</strong>
                    <strong v-else>Zapisz</strong>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <ModalTable
      ref="modalTable"
      @onModalCompleted="onModalCompleted"
      @onModalRejected="onModalRejected"
    ></ModalTable>
  </div>
</template>

<style></style>
