<script setup>
import { ref, watch } from 'vue';
import ClusterMap from '@/components/map/ClusterMap.vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { getConstructedMarkerForTransportOrderActionsPerUserOrderSetsMap as getMarker } from '../../helpers/transportActionMarkersHelper';
import TransportOrderSummary from '@/components/transport/TransportOrderSummary.vue';
import TransportRepoFilesWithFilters from '../fileRepositories/TransportRepoFilesWithFilters.vue';
import { copyToClipboard } from '../../helpers/utilsHelper';
import {
  COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME,
  SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME
} from '../../data/constants/buttonsNamesConstants';
import { useMapLogic } from '../../composables/mapLogic';
import { showOnGoogleMaps } from '../../helpers/navigator';

const RepositoryTransportOrders = RepositoryFactory.get('transportOrders');
const props = defineProps({ orderId: Number });
const isLoading = ref(false);
const orderActionsPerUserOrderSets = ref(null);
const alreadyLoadedTabs = ref({ summary: true, filesTable: false });
const disabledUserOrdersIds = ref([]);
const { clusterMap } = useMapLogic();

watch(
  () => props.orderId,
  () => {
    initialize();
  }
);

// created
if (props.orderId != null) {
  initialize();
}

function initialize() {
  fetchMarkersInfo();
}

async function fetchMarkersInfo() {
  isLoading.value = true;

  orderActionsPerUserOrderSets.value =
    await RepositoryTransportOrders.getTransportOrderActionsPerUserOrderSets(props.orderId);
  prepareMarkersForMap();

  isLoading.value = false;
}

function prepareMarkersForMap() {
  let markers = new Array();

  if (Array.isArray(orderActionsPerUserOrderSets.value)) {
    orderActionsPerUserOrderSets.value.forEach((actionsPerUserOrderSet) => {
      let marker = getMarker(actionsPerUserOrderSet);

      if (
        marker &&
        !disabledUserOrdersIds.value.includes(actionsPerUserOrderSet?.id_user_order_transport)
      ) {
        markers.push(marker);
      }
    });
  }

  clusterMap.value.initializeMap({ markersData: markers });
}

function onMapClickableClicked(clickable) {
  let clickActionType = clickable.getAttribute('click_action_type');
  const lat = clickable.getAttribute('lat');
  const lon = clickable.getAttribute('lon');

  switch (clickActionType) {
    case COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME:
      if (lat != undefined && lon != undefined) {
        copyToClipboard(lat + ',' + lon);
      }
      break;
    case SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME:
      if (lat != undefined && lon != undefined) {
        showOnGoogleMaps(lat, lon);
      }
      break;
  }
}

function onTabClicked(tabId) {
  switch (tabId) {
    case 0:
      alreadyLoadedTabs.value.summary = true;
      break;
    case 1:
      alreadyLoadedTabs.value.filesTable = true;
      break;
  }
}

function onDisabledUserOrdersChanged(updatedDisabledUserOrdersIds) {
  disabledUserOrdersIds.value = updatedDisabledUserOrdersIds;
  prepareMarkersForMap();
}
</script>

<template>
  <div class="row">
    <div class="col-lg-8">
      <div class="ibox">
        <div class="ibox-content" :class="{ 'sk-loading': isLoading }">
          <div class="sk-spinner sk-spinner-three-bounce">
            <div class="sk-bounce1"></div>
            <div class="sk-bounce2"></div>
            <div class="sk-bounce3"></div>
          </div>

          <ClusterMap
            ref="clusterMap"
            height="700px"
            :showZeroPositions="false"
            :refreshable="true"
            @clickableClicked="onMapClickableClicked"
            :disableClusteringAtZoomOverride="20"
            :maxClusterRadiusOverride="1"
          />
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="tabs-container">
        <ul class="nav nav-tabs" role="tablist">
          <li @click="onTabClicked(0)">
            <a
              class="nav-link active"
              data-toggle="tab"
              href="#transport-order-actions-details-tab-1"
            >
              <i class="fa fa-info" />
              Informacje
            </a>
          </li>
          <li @click="onTabClicked(1)">
            <a class="nav-link" data-toggle="tab" href="#transport-order-actions-details-tab-2">
              <i class="fa fa-file-image-o" />
              Zdjęcia
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div role="tabpanel" id="transport-order-actions-details-tab-1" class="tab-pane active">
            <div class="panel-body">
              <TransportOrderSummary
                v-if="alreadyLoadedTabs.summary"
                :orderId="props.orderId"
                @disabledUserOrdersChanged="onDisabledUserOrdersChanged"
              />
            </div>
          </div>

          <div role="tabpanel" id="transport-order-actions-details-tab-2" class="tab-pane">
            <div class="panel-body">
              <TransportRepoFilesWithFilters
                v-if="alreadyLoadedTabs.filesTable"
                :orderId="orderId"
                :urlOverride="`ordertransport/${props.orderId}/transportFiles`"
                :longerSearchBar="true"
                modalImageContainerId="modalImageContainerTransportOrderActionsPhotos"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
