import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { isNotEmptyArray } from '../helpers/utilsHelper';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
const RepositoryDictionaries = RepositoryFactory.get('dictionaries');
const LOCAL_STORAGE_STATE_KEY = 'sideMenuItemsState';
const LOCAL_STORAGE_ORDER_STATUSES_KEY = 'orderStatuses';
const LOCAL_STORAGE_ORDER_IMPLEMENTATION_PHASES_KEY = 'orderImplementationPhases';
const LOCAL_STORAGE_ORDER_GEODESY_PHASES_KEY = 'orderGeodesyPhases';

export const useSideMenuStore = defineStore('sideMenu', () => {
  const sideMenuItemsPersistedState = ref(localStorage.getItem(LOCAL_STORAGE_STATE_KEY));

  function getPersistedState() {
    return sideMenuItemsPersistedState.value ? JSON.parse(sideMenuItemsPersistedState.value) : {};
  }

  function persistData(key, value) {
    let currentPersistedState = getPersistedState();
    currentPersistedState[key] = value;
    sideMenuItemsPersistedState.value = JSON.stringify(currentPersistedState);
    localStorage.setItem(LOCAL_STORAGE_STATE_KEY, sideMenuItemsPersistedState.value);
  }

  const orderStatusesItemsFetchingInProgress = ref(false);
  const orderImplementationPhasesItemsFetchingInProgress = ref(false);
  const orderGeodesyPhasesItemsFetchingInProgress = ref(false);

  const orderStatuses = computed(() => {
    const persistedStatusesState = getPersistedState()[LOCAL_STORAGE_ORDER_STATUSES_KEY];

    if (persistedStatusesState) {
      return persistedStatusesState;
    }

    if (!orderStatusesItemsFetchingInProgress.value) {
      fetchOrderStatuses();
    }

    return [];
  });

  const orderImplementationPhases = computed(() => {
    const persistedImplementationPhasesState =
      getPersistedState()[LOCAL_STORAGE_ORDER_IMPLEMENTATION_PHASES_KEY];

    if (persistedImplementationPhasesState) {
      return persistedImplementationPhasesState;
    }

    if (!orderImplementationPhasesItemsFetchingInProgress.value) {
      fetchOrderImplementationPhases();
    }

    return [];
  });

  const orderGeodesyPhases = computed(() => {
    const persistedGeodesyPhasesState = getPersistedState()[LOCAL_STORAGE_ORDER_GEODESY_PHASES_KEY];

    if (persistedGeodesyPhasesState) {
      return persistedGeodesyPhasesState;
    }

    if (!orderGeodesyPhasesItemsFetchingInProgress.value) {
      fetchOrderGeodesyPhases();
    }

    return [];
  });

  function fetchOrderStatuses() {
    orderStatusesItemsFetchingInProgress.value = true;

    RepositoryDictionaries.getDictionaryElementsObject(1, 1000, '', '', '', 'orderstatus', null)
      .then((data) => {
        const statuses = isNotEmptyArray(data?.data) ? data.data : [];
        persistData(LOCAL_STORAGE_ORDER_STATUSES_KEY, statuses);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        orderStatusesItemsFetchingInProgress.value = false;
      });
  }

  function fetchOrderImplementationPhases() {
    orderImplementationPhasesItemsFetchingInProgress.value = true;

    RepositoryDictionaries.getDictionaryElementsObject(
      1,
      1000,
      '',
      '',
      '',
      'implementationphase',
      null
    )
      .then((data) => {
        const implementationPhases = isNotEmptyArray(data?.data) ? data.data : [];
        persistData(LOCAL_STORAGE_ORDER_IMPLEMENTATION_PHASES_KEY, implementationPhases);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        orderImplementationPhasesItemsFetchingInProgress.value = false;
      });
  }

  function fetchOrderGeodesyPhases() {
    orderGeodesyPhasesItemsFetchingInProgress.value = true;

    RepositoryDictionaries.getDictionaryElementsObject(1, 1000, '', '', '', 'geodesyphase', null)
      .then((data) => {
        const geodesyPhases = isNotEmptyArray(data?.data) ? data.data : [];
        persistData(LOCAL_STORAGE_ORDER_GEODESY_PHASES_KEY, geodesyPhases);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        orderGeodesyPhasesItemsFetchingInProgress.value = false;
      });
  }

  function resetState() {
    localStorage.removeItem(LOCAL_STORAGE_STATE_KEY);
    sideMenuItemsPersistedState.value = null;
  }

  return { orderStatuses, orderImplementationPhases, orderGeodesyPhases, resetState };
});
