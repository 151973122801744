import { getDateWithoutTime, getLocalFormattedDateAndTime } from '@/helpers/dateFormatHelper.js';
import { createToaster } from '@meforma/vue-toaster';

export function isNotEmptyArray(object) {
  return Array.isArray(object) && object.length > 0;
}

export function showFileInNewTab(fileToShow) {
  if (fileToShow?.resource?.path != null) {
    window.open(import.meta.env.VITE_VUE_APP_IMAGES_URL + fileToShow.resource.path, '_blank');
  }
}

export function getCurrentDate() {
  return getDateWithoutTime(getLocalFormattedDateAndTime(new Date().toJSON()));
}

export function copyToClipboard(textToCopy, overwriteMessage = null) {
  navigator.clipboard.writeText(textToCopy);
  const toaster = createToaster({ position: 'top-right' });
  const message = overwriteMessage ?? 'Skopiowano do schowka: ' + textToCopy;

  toaster.show(message, { duration: 3000, type: 'info' });
}

export function generateRandomString(length, numbers = false, specialCharacters = false) {
  let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  if (numbers) chars += '0123456789';
  if (specialCharacters) chars += '!@#$%^&*()';

  const charLength = chars.length;
  let result = '';

  for (var i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }

  return result;
}

export function logFormData(formData) {
  for (var pair of formData.entries()) {
    console.log(pair[0] + ': ' + pair[1]);
  }
}

export function pushSafe(array, obj) {
  if (Array.isArray(array)) {
    array.push(obj);
  } else {
    array = [obj];
  }

  return array;
}

export function isNullOrEmpty(text) {
  return !text || text.length === 0;
}
