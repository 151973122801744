<script setup>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { getDateWithoutTime } from '@/helpers/dateFormatHelper.js';
import Swal from 'sweetalert2';
import { MODAL_TRANSPORT_ORDER_ADDITIONAL_STATUSES_TABLE } from '@/data/constants/modalConstants.js';
import { handleResponseWithMessages } from '@/helpers/wwwHelper.js';
import { useNavigationPanelStore } from '@/stores/navigationPanel';
import { createToaster } from '@meforma/vue-toaster';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import ModalTable from '@/components/utils/ModalTable.vue';

const RepositoryTransportOrders = RepositoryFactory.get('transportOrders');
const props = defineProps({ id: Number, collapsed: Boolean });
const emit = defineEmits([
  'onIsLoadingChanged',
  'onOrderLoaded',
  'refresh',
  'collapsePanel',
  'expandPanel'
]);

const isLoading = ref(false);
const order = ref(null);
const router = useRouter();
const navigationPanelStore = useNavigationPanelStore();
const toaster = createToaster({ position: 'top-right', duration: 3000 });
const modalTable = ref();

const delayDays = computed(() => {
  if (order.value?.delay == null) return '-';
  else if (order.value.delay <= 0) return 'brak';

  if (order.value.delay === 1) return order.value.delay.toString() + ' dzień';
  else return order.value.delay.toString() + ' dni';
});

watch(
  () => props.id,
  () => getOrderDetails()
);

watch(isLoading, () => {
  emit('onIsLoadingChanged', isLoading.value);
});

watch(order, () => {
  emit('onOrderLoaded', order.value);
});

async function getOrderDetails() {
  isLoading.value = true;

  navigationPanelStore.setTransportOrderNumber('-');
  order.value = await RepositoryTransportOrders.getTransportOrderDetailsGeneral(props.id);
  navigationPanelStore.setTransportOrderNumber(order.value?.order_number);

  isLoading.value = false;
}

function goToUserDetails(id) {
  if (id == null) return;

  router.push({ name: 'userDetails', params: { id } });
}

function onCompleteOrderButtonClicked() {
  const isThereSthLeft = order.value?.left_from_last_worker > 0;
  let additionalText = '';

  if (isThereSthLeft) {
    additionalText = `<br/><br/><span style="color: indianRed">Liczba pozostałych elementów: ${order.value.left_from_last_worker}</span>`;
  }

  Swal.fire(
    getSwalConfiguration(
      'Zakończenie zlecenia',
      'Czy na pewno chcesz zakończyć zlecenie?' + additionalText
    )
  ).then((result) => {
    if (result.value) {
      completeOrder();
    }
  });
}

async function completeOrder() {
  var response = await RepositoryTransportOrders.completeOrder(order.value?.id);

  handleResponseWithMessages(response, 'Zakończono zlecenie.', () => {
    emit('refresh');
  });
}

function onArchiveOrderButtonClicked() {
  Swal.fire(
    getSwalConfiguration('Zarchiwizowanie zlecenia', 'Czy na pewno chcesz zarchiwizować zlecenie?')
  ).then((result) => {
    if (result.value) {
      archiveOrder();
    }
  });
}

async function archiveOrder() {
  var response = await RepositoryTransportOrders.archiveOrder(order.value?.id);

  handleResponseWithMessages(response, 'Zarchiwizowano zlecenie.', () => {
    emit('refresh');
  });
}

function onDeleteOrderButtonClicked() {
  Swal.fire(
    getSwalConfiguration('Usunięcie zlecenia', 'Czy na pewno chcesz usunąć zlecenie?')
  ).then((result) => {
    if (result.value) {
      deleteOrder();
    }
  });
}

async function deleteOrder() {
  var response = await RepositoryTransportOrders.deleteOrder(order.value?.id);

  handleResponseWithMessages(response, 'Usunięto zlecenie.', () => {
    router.push({ name: 'transportOrdersTable' });
  });
}

function onPrioritySwitchClicked() {
  if (order.value.priority) {
    toaster.show('Priorytet włączony, zmiana tego ustawienia nie jest możliwa.', {
      type: 'warning'
    });
    return;
  }

  Swal.fire(
    getSwalConfiguration('Zmiana priorytetu', 'Czy na pewno chcesz ustawić priorytet zlecenia?')
  ).then((result) => {
    if (result.value) {
      setPriority();
    }
  });
}

async function setPriority() {
  var response = await RepositoryTransportOrders.setTransportOrderPriority(order.value?.id);

  handleResponseWithMessages(response, 'Ustawiono priorytet zlecenia.', () => {
    emit('refresh');
  });
}

function onModalCompleted(data) {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_TRANSPORT_ORDER_ADDITIONAL_STATUSES_TABLE:
      changeTransportOrderAdditionalStatus(data);
      break;
  }
}

function onModalRejected() {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_TRANSPORT_ORDER_ADDITIONAL_STATUSES_TABLE:
      changeTransportOrderAdditionalStatus(null);
      break;
  }
}

async function changeTransportOrderAdditionalStatus(newStatus) {
  var response = await RepositoryTransportOrders.changeTransportOrderAdditionalStatus(
    order.value?.id,
    newStatus?.id
  );

  handleResponseWithMessages(response, 'Status nadrzędny został zmieniony.', () => {
    order.value.additionalStatus = newStatus;
  });
}

// created
getOrderDetails();
</script>

<template>
  <div v-show="!props.collapsed" class="ibox">
    <div class="ibox-title pr-3">
      <div class="row">
        <div class="col-12" style="display: flex; justify-content: space-between">
          <div v-if="order">
            <button
              class="btn btn-outline btn-info btn-xs mr-2"
              @click="onArchiveOrderButtonClicked"
            >
              Zarchiwizuj
            </button>
            <button class="btn btn-outline btn-danger btn-xs" @click="onDeleteOrderButtonClicked">
              Usuń
            </button>
          </div>

          <button
            class="btn btn-primary btn-xs float-right ml-2 mr-1"
            @click="emit('collapsePanel')"
          >
            Ukryj panel
          </button>
        </div>
      </div>
    </div>

    <div class="ibox-content profile-content" :class="{ 'sk-loading': isLoading }">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <table class="mt-4 mb-4 equal-columns-table">
        <tr v-if="order?.order_transport_status !== undefined">
          <td>Status:</td>
          <td>
            <span
              class="label label-primary"
              :style="
                order?.order_transport_status?.color != null
                  ? 'background-color:' + order.order_transport_status.color
                  : ''
              "
            >
              {{ order?.order_transport_status?.name ?? '-' }}</span
            >
          </td>
        </tr>

        <tr v-if="order?.priority !== undefined">
          <td>Priorytet:</td>
          <td>
            <div class="switch">
              <div class="onoffswitch" @mouseup="onPrioritySwitchClicked">
                <input type="checkbox" class="onoffswitch-checkbox" :checked="order?.priority" />
                <label class="onoffswitch-label">
                  <span class="onoffswitch-inner"></span>
                  <span class="onoffswitch-switch"></span>
                </label>
              </div>
            </div>
          </td>
        </tr>

        <tr v-if="order?.additionalStatus !== undefined">
          <td>Status nadrzędny:</td>
          <td>
            <span
              class="label label-primary"
              :style="
                order?.additionalStatus?.color != null
                  ? 'background-color:' + order.additionalStatus.color
                  : ''
              "
            >
              {{ order?.additionalStatus?.name ?? '-' }}
            </span>
            <button
              type="button"
              @click="modalTable.showModal(MODAL_TRANSPORT_ORDER_ADDITIONAL_STATUSES_TABLE)"
              class="btn btn-xs btn-outline btn-link"
            >
              Zmień
            </button>
          </td>
        </tr>

        <tr v-if="order?.storage_date !== undefined">
          <td>Termin nadania miejsca składowania:</td>
          <td>
            {{ order?.storage_date ? getDateWithoutTime(order.storage_date) : '-' }}
          </td>
        </tr>

        <tr v-if="order?.deadline_offset !== undefined">
          <td>Przesunięcie:</td>
          <td>
            <i
              v-if="order?.deadline_offset"
              class="fa fa-long-arrow-right text-info"
              style="font-size: 23px"
            />
            <span v-else>-</span>
          </td>
        </tr>
        <tr v-if="order?.note_presence !== undefined">
          <td>Notatka:</td>
          <td>
            <img
              v-if="order?.note_presence > 0"
              src="/static/img/markerIcons/exclamation_mark_circle_icon.png"
              alt="Notatka"
              style="width: 22px; height: 22px; padding-right: 0px"
            />
            <span v-else>-</span>
          </td>
        </tr>

        <tr v-if="order?.order_number !== undefined">
          <td>Numer zlecenia:</td>
          <td>{{ order?.order_number ?? '-' }}</td>
        </tr>

        <tr v-if="order?.region !== undefined">
          <td>Region:</td>
          <td>
            {{ order?.region?.name ?? '-' }}
          </td>
        </tr>

        <tr v-if="order?.gmina !== undefined">
          <td>Gmina:</td>
          <td>
            {{ order?.gmina?.name ?? '-' }}
          </td>
        </tr>

        <tr v-if="order?.powiat !== undefined">
          <td>Powiat:</td>
          <td>
            {{ order?.powiat?.name ?? '-' }}
          </td>
        </tr>

        <tr v-if="order?.note !== undefined">
          <td>Notatka brygadzisty:</td>
          <td>{{ order?.note ?? '-' }}</td>
        </tr>

        <tr v-if="order?.worker !== undefined">
          <td>Brygadzista:</td>
          <td>
            <span
              v-if="
                order?.worker?.id != null &&
                order?.worker?.firstName != null &&
                order?.worker?.lastName != null
              "
            >
              <a @click="goToUserDetails(order.worker.id)" :class="'text-navy'">
                {{ order.worker.firstName + ' ' + order.worker.lastName }}
              </a>
            </span>
            <span v-else>-</span>
          </td>
        </tr>

        <tr v-if="order?.delay !== undefined">
          <td>Opóźnienie:</td>
          <td>{{ delayDays }}</td>
        </tr>
      </table>

      <div class="user-button mt-2" v-if="order">
        <div class="row">
          <div class="col-12" style="display: flex; justify-content: flex-end">
            <button
              class="btn btn-primary btn-xs float-right ml-2 mr-1"
              @click="onCompleteOrderButtonClicked"
            >
              Zakończ zlecenie
            </button>
          </div>
        </div>
      </div>
    </div>

    <ModalTable
      ref="modalTable"
      @onModalCompleted="onModalCompleted"
      @onModalRejected="onModalRejected"
    ></ModalTable>
  </div>

  <div v-show="props.collapsed" class="ibox">
    <div class="ibox-title pr-3" style="height: 100vh">
      <div class="row">
        <div class="col-12" style="display: flex; justify-content: space-between">
          <button class="btn btn-primary btn-xs float-right ml-2 mr-1" @click="emit('expandPanel')">
            Pokaż panel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
td {
  padding-bottom: 0.75rem;
}

tr :first-child {
  text-align: right;
  padding-right: 0.5rem;
}

.equal-columns-table td:nth-child(1) {
  /* width: auto; */
  /* white-space:nowrap; */
  /* width: 50%; */
  vertical-align: text-top;
}

.equal-columns-table td:nth-child(2) {
  /* width: auto; */
  overflow-wrap: break-word;
}

.equal-columns-table {
  width: 100%;
  table-layout: fixed;
}
</style>
