<script setup>
import { ref, computed, onMounted } from 'vue';
/* eslint-disable no-undef */
import {
  MODAL_USERS_TABLE,
  MODAL_ORDER_STATUSES_TABLE,
  MODAL_ORDER_TYPES_TABLE,
  MODAL_DIRECTIONS_TABLE,
  MODAL_REGIONS_TABLE,
  MODAL_COUNTIES_TABLE,
  MODAL_ORDER_REALIZATION_PHASES_TABLE,
  MODAL_ORDER_GEODETIC_PHASES_TABLE
} from '@/data/constants/modalConstants.js';
import ModalInputField from '@/components/utils/ModalInputField.vue';
import ModalTable from '@/components/utils/ModalTable.vue';

const props = defineProps({
  longerSearchBar: { type: Boolean, default: false },
  userFilterEnabled: { type: Boolean, default: true },
  hideSearchBar: { type: Boolean, default: false },
  oneFilterPerRow: { type: Boolean, default: false },
  initialFilter: { type: Object, default: null }
});

const emit = defineEmits(['fetchData']);

const modalTable = ref();
const searchBox = ref('');
const filterPanel = ref({
  coordinator: null,
  orderStatus: null,
  orderType: null,
  priorityChange: null,
  direction: null,
  region: null,
  county: null,
  realizationPhase: null,
  geodeticPhase: null
});

const filterPanelDisplay = ref({
  coordinatorDisplayName: null,
  orderStatusDisplayName: null,
  orderTypeDisplayName: null,
  directionDisplayName: null,
  regionDisplayName: null,
  countyDisplayName: null,
  orderRealizationPhaseDisplayName: null,
  orderGeodeticPhaseDisplayName: null
});

const appliedFilter = ref(new Object());

const filtersCount = computed(() => {
  let count = 0;

  for (const property in appliedFilter.value) {
    if (appliedFilter.value[property] != null) count++;
  }

  return count;
});

const coordinatorApplied = computed(() => {
  return filterApplied(filterPanel.value?.coordinator?.id, appliedFilter.value?.coordinator?.id);
});

const orderStatusApplied = computed(() => {
  return filterApplied(filterPanel.value?.orderStatus?.id, appliedFilter.value?.orderStatus?.id);
});

const orderTypeApplied = computed(() => {
  return filterApplied(filterPanel.value?.orderType?.id, appliedFilter.value?.orderType?.id);
});

const priorityChangeApplied = computed(() => {
  return filterApplied(filterPanel.value?.priorityChange, appliedFilter.value?.priorityChange);
});

const directionApplied = computed(() => {
  return filterApplied(filterPanel.value?.direction?.id, appliedFilter.value?.direction?.id);
});

const regionApplied = computed(() => {
  return filterApplied(filterPanel.value?.region?.id, appliedFilter.value?.region?.id);
});

const countyApplied = computed(() => {
  return filterApplied(filterPanel.value?.county?.id, appliedFilter.value?.county?.id);
});

const orderRealizationPhaseApplied = computed(() => {
  return filterApplied(
    filterPanel.value?.realizationPhase?.id,
    appliedFilter.value?.realizationPhase?.id
  );
});

const orderGeodeticPhaseApplied = computed(() => {
  return filterApplied(
    filterPanel.value?.geodeticPhase?.id,
    appliedFilter.value?.geodeticPhase?.id
  );
});

if (props.initialFilter != null) assignInitialFilter();

onMounted(() => {
  configureCollapsingFilterPanel();
});

function configureCollapsingFilterPanel() {
  $('.collapse-link-modified')
    .off('click')
    .on('click', function (e) {
      e.preventDefault();
      var ibox = $(this).closest('div.ibox');
      var button = $(this).find('i');
      var content = ibox.children('.ibox-content');
      content.slideToggle(200);
      button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
      setTimeout(function () {
        ibox.resize();
        ibox.find('[id^=map-]').resize();
      }, 50);
    });
}

function onModalCompleted(data) {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_USERS_TABLE:
      filterPanel.value.coordinator = data;
      filterPanelDisplay.value.coordinatorDisplayName =
        (data?.firstName ?? '') + ' ' + (data?.lastName ?? '');
      break;
    case MODAL_ORDER_STATUSES_TABLE:
      filterPanel.value.orderStatus = data;
      filterPanelDisplay.value.orderStatusDisplayName = data?.name ?? '';
      break;
    case MODAL_ORDER_TYPES_TABLE:
      filterPanel.value.orderType = data;
      filterPanelDisplay.value.orderTypeDisplayName = data?.name ?? '';
      break;
    case MODAL_DIRECTIONS_TABLE:
      filterPanel.value.direction = data;
      filterPanelDisplay.value.directionDisplayName = data?.name ?? '';
      break;
    case MODAL_REGIONS_TABLE:
      filterPanel.value.region = data;
      filterPanelDisplay.value.regionDisplayName = data?.name ?? '';
      break;
    case MODAL_COUNTIES_TABLE:
      filterPanel.value.county = data;
      filterPanelDisplay.value.countyDisplayName = data?.name ?? '';
      break;
    case MODAL_ORDER_REALIZATION_PHASES_TABLE:
      filterPanel.value.realizationPhase = data;
      filterPanelDisplay.value.orderRealizationPhaseDisplayName = data?.name ?? '';
      break;
    case MODAL_ORDER_GEODETIC_PHASES_TABLE:
      filterPanel.value.geodeticPhase = data;
      filterPanelDisplay.value.orderGeodeticPhaseDisplayName = data?.name ?? '';
      break;
  }
}

function onModalRejected() {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_USERS_TABLE:
      filterPanel.value.coordinator = null;
      filterPanelDisplay.value.coordinatorDisplayName = null;
      break;
    case MODAL_ORDER_STATUSES_TABLE:
      filterPanel.value.orderStatus = null;
      filterPanelDisplay.value.orderStatusDisplayName = null;
      break;
    case MODAL_ORDER_TYPES_TABLE:
      filterPanel.value.orderType = null;
      filterPanelDisplay.value.orderTypeDisplayName = null;
      break;
    case MODAL_DIRECTIONS_TABLE:
      filterPanel.value.direction = null;
      filterPanelDisplay.value.directionDisplayName = null;
      break;
    case MODAL_REGIONS_TABLE:
      filterPanel.value.region = null;
      filterPanelDisplay.value.regionDisplayName = null;
      break;
    case MODAL_COUNTIES_TABLE:
      filterPanel.value.county = null;
      filterPanelDisplay.value.countyDisplayName = null;
      break;
    case MODAL_ORDER_REALIZATION_PHASES_TABLE:
      filterPanel.value.realizationPhase = null;
      filterPanelDisplay.value.orderRealizationPhaseDisplayName = null;
      break;
    case MODAL_ORDER_GEODETIC_PHASES_TABLE:
      filterPanel.value.geodeticPhase = null;
      filterPanelDisplay.value.orderGeodeticPhaseDisplayName = null;
      break;
  }
}

function onClearAllFiltersButtonClicked() {
  for (const property in filterPanel.value) {
    filterPanel.value[property] = null;
  }

  for (const property in filterPanelDisplay.value) {
    filterPanelDisplay.value[property] = null;
  }

  triggerFetchData();
}

function onApplyFiltersButtonClicked() {
  triggerFetchData();
}

function getUserFilter() {
  let userFilter = new Object();

  if (filterPanel.value?.coordinator?.id != null)
    userFilter.id_coordinator_opl = filterPanel.value.coordinator.id;

  if (filterPanel.value?.orderStatus?.id != null)
    userFilter.id_order_status = filterPanel.value.orderStatus.id;

  if (filterPanel.value?.orderType?.id != null)
    userFilter.id_order_type = filterPanel.value.orderType.id;

  if (filterPanel.value?.priorityChange != null)
    userFilter.priority = filterPanel.value.priorityChange ? 1 : 0;

  if (filterPanel.value?.direction?.id != null)
    userFilter.id_direction = filterPanel.value.direction.id;

  if (filterPanel.value?.region?.id != null) userFilter.id_region = filterPanel.value.region.id;

  if (filterPanel.value?.county?.id != null) userFilter.id_powiat = filterPanel.value.county.id;

  if (filterPanel.value?.realizationPhase?.id != null)
    userFilter.id_implementation_phase = filterPanel.value.realizationPhase.id;

  if (filterPanel.value?.geodeticPhase?.id != null)
    userFilter.id_geodesy_phase = filterPanel.value.geodeticPhase.id;

  return userFilter;
}

function updateAppliedFilters() {
  appliedFilter.value = Object.assign({}, filterPanel.value);
}

function filterApplied(filterPanelProp, appliedFilterProp) {
  if (appliedFilterProp != null && filterPanelProp != null)
    return appliedFilterProp === filterPanelProp;
  else return appliedFilterProp == filterPanelProp;
}

function search() {
  triggerFetchData();
}

function triggerFetchData() {
  emit('fetchData', {
    search: searchBox.value,
    filter: getUserFilter()
  });
}

function assignInitialFilter() {
  assignOrderStatusInitialFilter();
  assignOrderRealizationPhaseInitialFilter();
  assignOrderGeodeticPhaseInitialFilter();
}

function assignOrderStatusInitialFilter() {
  if (props.initialFilter?.id_order_status != null) {
    let data = {};
    data.id = props.initialFilter.id_order_status;

    switch (props.initialFilter.id_order_status) {
      case '1':
        data.name = 'Do dyspozycji';
        break;
      case '2':
        data.name = 'W produkcji';
        break;
      case '3':
        data.name = 'Przerwane';
        break;
      case '4':
        data.name = 'Anulowane';
        break;
      case '5':
        data.name = 'Zakończone rozliczone';
        break;
    }

    filterPanel.value.orderStatus = data;
    filterPanelDisplay.value.orderStatusDisplayName = data?.name ?? '';
  }
}

function assignOrderRealizationPhaseInitialFilter() {
  if (props.initialFilter?.id_implementation_phase != null) {
    let data = {};
    data.id = props.initialFilter.id_implementation_phase;

    switch (props.initialFilter.id_implementation_phase) {
      case '1':
        data.name = 'Nierozpoczęty demontaż';
        break;
      case '2':
        data.name = 'Rozpoczęty demontaż';
        break;
      case '3':
        data.name = 'Przerwany demontaż';
        break;
      case '4':
        data.name = 'Zakończony demontaż do zabrania';
        break;
      case '5':
        data.name = 'Zakończony demontaż prośba o PO';
        break;
      case '6':
        data.name = 'Zrealizowany demontaż';
        break;
      case '7':
        data.name = 'Geodezja';
        break;
    }

    filterPanel.value.realizationPhase = data;
    filterPanelDisplay.value.orderRealizationPhaseDisplayName = data?.name ?? '';
  }
}

function assignOrderGeodeticPhaseInitialFilter() {
  if (props.initialFilter?.id_geodesy_phase != null) {
    let data = {};
    data.id = props.initialFilter.id_geodesy_phase;

    switch (props.initialFilter.id_geodesy_phase) {
      case '1':
        data.name = 'Do zlecenia geodecie';
        break;
      case '2':
        data.name = 'W realizacji';
        break;
      case '3':
        data.name = 'Przesłano do weryfikacji Orange';
        break;
    }

    filterPanel.value.geodeticPhase = data;
    filterPanelDisplay.value.orderGeodeticPhaseDisplayName = data?.name ?? '';
  }
}

defineExpose({ updateAppliedFilters });
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="row mb-3">
            <div v-if="props.userFilterEnabled" class="col-sm-6">
              <a type="button" class="collapse-link-modified btn btn-w-m btn-default">
                <span v-if="filtersCount > 0" class="badge badge-warning">{{ filtersCount }}</span>
                Filtry
                <i class="fa fa-chevron-down"></i>
              </a>
            </div>

            <div
              v-if="!props.hideSearchBar"
              :class="
                props.longerSearchBar
                  ? 'col-sm-4 ' + (props.userFilterEnabled ? 'offset-sm-2' : 'offset-sm-8')
                  : 'col-sm-3 ' + (props.userFilterEnabled ? 'offset-sm-3' : 'offset-sm-9')
              "
            >
              <div class="input-group">
                <input
                  placeholder="Szukaj.."
                  type="text"
                  class="form-control form-control-sm"
                  v-model="searchBox"
                  v-on:keyup.enter="search"
                />
                <span class="input-group-append">
                  <button @click="search" type="button" class="btn btn-sm btn-primary">
                    <i class="fa fa-search"></i> Szukaj
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="ibox-content" style="display: none">
            <div class="row">
              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Status zlecenia"
                  :titleCssClass="orderStatusApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.orderStatusDisplayName"
                  @showModal="modalTable.showModal(MODAL_ORDER_STATUSES_TABLE)"
                />
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Etap realizacji"
                  :titleCssClass="orderRealizationPhaseApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.orderRealizationPhaseDisplayName"
                  @showModal="modalTable.showModal(MODAL_ORDER_REALIZATION_PHASES_TABLE)"
                />
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Etap geodezji"
                  :titleCssClass="orderGeodeticPhaseApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.orderGeodeticPhaseDisplayName"
                  @showModal="modalTable.showModal(MODAL_ORDER_GEODETIC_PHASES_TABLE)"
                />
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Typ zlecenia"
                  :titleCssClass="orderTypeApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.orderTypeDisplayName"
                  @showModal="modalTable.showModal(MODAL_ORDER_TYPES_TABLE)"
                />
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <div class="form-group">
                  <label
                    class="col-form-label pt-0"
                    :class="priorityChangeApplied ? '' : 'text-warning'"
                    >Priorytet</label
                  >

                  <div class="btn-group btn-group-toggle" style="display: block">
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.priorityChange == null ? 'active' : ''"
                    >
                      <input type="radio" :value="null" v-model="filterPanel.priorityChange" />
                      -
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.priorityChange === true ? 'active' : ''"
                    >
                      <input type="radio" :value="true" v-model="filterPanel.priorityChange" />
                      Tak
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.priorityChange === false ? 'active' : ''"
                    >
                      <input type="radio" :value="false" v-model="filterPanel.priorityChange" />
                      Nie
                    </label>
                  </div>
                </div>
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Rodzaj zlecenia"
                  :titleCssClass="directionApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.directionDisplayName"
                  @showModal="modalTable.showModal(MODAL_DIRECTIONS_TABLE)"
                />
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Koordynator"
                  :titleCssClass="coordinatorApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.coordinatorDisplayName"
                  @showModal="modalTable.showModal(MODAL_USERS_TABLE)"
                />
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Region"
                  :titleCssClass="regionApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.regionDisplayName"
                  @showModal="modalTable.showModal(MODAL_REGIONS_TABLE)"
                />
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Powiat"
                  :titleCssClass="countyApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.countyDisplayName"
                  @showModal="modalTable.showModal(MODAL_COUNTIES_TABLE)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 offset-6">
                <button
                  class="btn btn-outline btn-info btn-xs float-right"
                  @click="onApplyFiltersButtonClicked"
                >
                  Zastosuj filtry
                </button>
                <button
                  class="btn btn-outline btn-danger btn-xs float-right mr-1"
                  @click="onClearAllFiltersButtonClicked"
                >
                  Wyczyść filtry
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalTable
      ref="modalTable"
      @onModalCompleted="onModalCompleted"
      @onModalRejected="onModalRejected"
    ></ModalTable>
  </div>
</template>

<style></style>
