import { ref, markRaw } from 'vue';
import DictionaryTable from '@/components/dictionaries/DictionaryTable.vue';
import UsersTable from '@/components/users/UsersTable.vue';
import OrdersTable from '@/components/orders/OrdersTable.vue';

import {
  MODAL_USERS_TABLE,
  MODAL_ORDERS_TABLE,
  MODAL_USER_TYPES_TABLE,
  MODAL_ORDER_STATUSES_TABLE,
  MODAL_ORDER_TYPES_TABLE,
  MODAL_REALIZATION_PRIORITIES_TABLE,
  MODAL_DIRECTIONS_TABLE,
  MODAL_REGIONS_TABLE,
  MODAL_COUNTIES_TABLE,
  MODAL_BOROUGHS_TABLE,
  MODAL_ORDER_REALIZATION_PHASES_TABLE,
  MODAL_ORDER_GEODETIC_PHASES_TABLE,
  MODAL_ACTION_TYPES_TABLE,
  MODAL_ORDER_RANGES_TABLE,
  MODAL_UNITS_OF_MEASURE_TABLE,
  MODAL_TRANSPORT_PHOTO_TYPES_TABLE,
  MODAL_TRANSPORT_ORDER_STATUSES_TABLE,
  MODAL_ORDER_INTERRUPTION_STATUSES_TABLE,
  MODAL_ORDER_SETTLEMENT_PHASES_TABLE,
  MODAL_REPORT_STATUSES_TABLE,
  MODAL_TRANSPORT_ORDER_ADDITIONAL_STATUSES_TABLE,
  MODAL_STORAGE_TYPES_TABLE,
  MODAL_DISMANTLING_FAILURE_REASONS_TABLE,
  MODAL_DEM_ELEM_TYPES_TABLE,
  MODAL_ACTIONS_REPORT_STATUSES_TABLE
} from '@/data/constants/modalConstants.js';

export function useModalTables(modalBase) {
  const modal = ref({
    type: null,
    key: 0,
    title: '',
    properties: null
  });

  function showModal(modalType, payload) {
    switch (modalType) {
      case MODAL_USERS_TABLE:
        if (payload?.additionalProps?.filter?.url != null) {
          if (payload.additionalProps.filter.url.includes('coordinator'))
            modal.value.title = 'Wybierz koordynatora: ';
          else if (payload.additionalProps.filter.url.includes('worker'))
            modal.value.title = 'Wybierz pracownika: ';
          else if (payload.additionalProps.filter.url.includes('driver'))
            modal.value.title = 'Wybierz kierowcę: ';
        } else modal.value.title = 'Wybierz użytkownika: ';

        modal.value.component = ref(markRaw(UsersTable));
        break;
      case MODAL_ORDERS_TABLE:
        modal.value.title = 'Wybierz zlecenie: ';
        modal.value.component = ref(markRaw(OrdersTable));
        break;
      case MODAL_USER_TYPES_TABLE:
        modal.value.title = 'Wybierz typ użytkownika: ';
        break;
      case MODAL_ORDER_STATUSES_TABLE:
        modal.value.title = 'Wybierz status zlecenia: ';
        break;
      case MODAL_ORDER_TYPES_TABLE:
        modal.value.title = 'Wybierz typ zlecenia: ';
        break;
      case MODAL_REALIZATION_PRIORITIES_TABLE:
        modal.value.title = 'Wybierz termin realizacji: ';
        break;
      case MODAL_DIRECTIONS_TABLE:
        modal.value.title = 'Wybierz rodzaj zlecenia: ';
        break;
      case MODAL_REGIONS_TABLE:
        modal.value.title = 'Wybierz region: ';
        break;
      case MODAL_COUNTIES_TABLE:
        modal.value.title = 'Wybierz powiat: ';
        break;
      case MODAL_BOROUGHS_TABLE:
        modal.value.title = 'Wybierz gminę: ';
        break;
      case MODAL_ORDER_REALIZATION_PHASES_TABLE:
        modal.value.title = 'Wybierz etap realizacji: ';
        break;
      case MODAL_ORDER_GEODETIC_PHASES_TABLE:
        modal.value.title = 'Wybierz etap geodezji: ';
        break;
      case MODAL_ACTION_TYPES_TABLE:
        modal.value.title = 'Wybierz typ akcji: ';
        break;
      case MODAL_ORDER_RANGES_TABLE:
        modal.value.title = 'Wybierz zakres: ';
        break;
      case MODAL_UNITS_OF_MEASURE_TABLE:
        modal.value.title = 'Wybierz jednostkę: ';
        break;
      case MODAL_TRANSPORT_PHOTO_TYPES_TABLE:
        modal.value.title = 'Wybierz typ zdjęcia: ';
        break;
      case MODAL_TRANSPORT_ORDER_STATUSES_TABLE:
        modal.value.title = 'Wybierz status zlecenia transportowego: ';
        break;
      case MODAL_ORDER_INTERRUPTION_STATUSES_TABLE:
        modal.value.title = 'Wybierz powód przerwania';
        break;
      case MODAL_ORDER_SETTLEMENT_PHASES_TABLE:
        modal.value.title = 'Wybierz status rozliczenia';
        break;
      case MODAL_REPORT_STATUSES_TABLE:
        modal.value.title = 'Wybierz status raportu';
        break;
      case MODAL_TRANSPORT_ORDER_ADDITIONAL_STATUSES_TABLE:
        modal.value.title = 'Wybierz nadrzędny status zlecenia transportowego: ';
        break;
      case MODAL_STORAGE_TYPES_TABLE:
        modal.value.title = 'Wybierz typ elementu: ';
        break;
      case MODAL_DISMANTLING_FAILURE_REASONS_TABLE:
        modal.value.title = 'Wybierz powód niepowodzenia';
        break;
      case MODAL_DEM_ELEM_TYPES_TABLE:
        modal.value.title = 'Wybierz typ punktu';
        break;
      case MODAL_ACTIONS_REPORT_STATUSES_TABLE:
        modal.value.title = 'Wybierz status raportu akcji';
        break;
    }

    modal.value.properties = {
      selectableItems: true,
      showHeader: false,
      showBorder: false,
      userFilterEnabled: false
    };

    //for dictionaries
    //TODO to refactor later
    switch (modalType) {
      case MODAL_USER_TYPES_TABLE:
        modal.value.properties.url = 'usertype';
        modal.value.properties.columns = [
          { key: 'name', header: 'Nazwa' },
          { key: 'note', header: 'Notatka' }
        ];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_ORDER_STATUSES_TABLE:
        modal.value.properties.url = 'orderstatus';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_ORDER_TYPES_TABLE:
        modal.value.properties.url = 'ordertype';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_REALIZATION_PRIORITIES_TABLE:
        modal.value.properties.url = 'realizationpriority';
        modal.value.properties.columns = [
          { key: 'name', header: 'Nazwa' },
          { key: 'color', header: 'Kolor' }
        ];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_DIRECTIONS_TABLE:
        modal.value.properties.url = 'direction';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_REGIONS_TABLE:
        modal.value.properties.url = 'region';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_COUNTIES_TABLE:
        modal.value.properties.url = 'powiat';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_BOROUGHS_TABLE:
        modal.value.properties.url = 'gmina';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_ORDER_REALIZATION_PHASES_TABLE:
        modal.value.properties.url = 'implementationphase';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_ORDER_GEODETIC_PHASES_TABLE:
        modal.value.properties.url = 'geodesyphase';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_ACTION_TYPES_TABLE:
        modal.value.properties.url = 'actiontype';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_ORDER_RANGES_TABLE:
        modal.value.properties.url = 'orderrange';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_UNITS_OF_MEASURE_TABLE:
        modal.value.properties.url = 'unitofmeasure';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_TRANSPORT_PHOTO_TYPES_TABLE:
        modal.value.properties.url = 'transportphototype';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_TRANSPORT_ORDER_STATUSES_TABLE:
        modal.value.properties.url = 'ordertransportstatus';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_ORDER_INTERRUPTION_STATUSES_TABLE:
        modal.value.properties.url = 'orderinterruptionstatus';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_ORDER_SETTLEMENT_PHASES_TABLE:
        modal.value.properties.url = 'settlementphase';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_REPORT_STATUSES_TABLE:
        modal.value.properties.url = 'registerraportstatus';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_TRANSPORT_ORDER_ADDITIONAL_STATUSES_TABLE:
        modal.value.properties.url = 'ordertransportadditionalstatus';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_STORAGE_TYPES_TABLE:
        modal.value.properties.url = 'storagetype';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_DISMANTLING_FAILURE_REASONS_TABLE:
        modal.value.properties.url = 'failureactionreason';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_DEM_ELEM_TYPES_TABLE:
        modal.value.properties.url = 'demelemtype';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
      case MODAL_ACTIONS_REPORT_STATUSES_TABLE:
        modal.value.properties.url = 'registerraportstatus';
        modal.value.properties.columns = [{ key: 'name', header: 'Nazwa' }];
        modal.value.component = ref(markRaw(DictionaryTable));
        break;
    }

    if (payload?.additionalProps) {
      modal.value.properties = Object.assign(modal.value.properties, payload.additionalProps);
    }

    modal.value.type = modalType;
    modal.value.key++;
    modalBase.value.show();
  }

  return { modal, showModal };
}
