export const MODAL_USERS_TABLE = 'users-table';
export const MODAL_ORDERS_TABLE = 'orders-table';
export const MODAL_USER_TYPES_TABLE = 'dictionary-table&userTypes';
export const MODAL_ORDER_STATUSES_TABLE = 'dictionary-table&orderStatuses';
export const MODAL_ORDER_TYPES_TABLE = 'dictionary-table&orderTypes';
export const MODAL_REALIZATION_PRIORITIES_TABLE = 'dictionary-table&realizationPriorities';
export const MODAL_DIRECTIONS_TABLE = 'dictionary-table&directions';
export const MODAL_REGIONS_TABLE = 'dictionary-table&regions';
export const MODAL_COUNTIES_TABLE = 'dictionary-table&counties';
export const MODAL_BOROUGHS_TABLE = 'dictionary-table&boroughs';
export const MODAL_ORDER_REALIZATION_PHASES_TABLE = 'dictionary-table&orderRealizationPhases';
export const MODAL_ORDER_GEODETIC_PHASES_TABLE = 'dictionary-table&orderGeodeticPhases';
export const MODAL_ACTION_TYPES_TABLE = 'dictionary-table&actiontype';
export const MODAL_ORDER_RANGES_TABLE = 'dictionary-table&orderrange';
export const MODAL_UNITS_OF_MEASURE_TABLE = 'dictionary-table&unitofmeasure';
export const MODAL_TRANSPORT_PHOTO_TYPES_TABLE = 'dictionary-table&transportphototype';
export const MODAL_TRANSPORT_ORDER_STATUSES_TABLE = 'dictionary-table&transportOrderStatuses';
export const MODAL_ORDER_INTERRUPTION_STATUSES_TABLE = 'dictionary-table&orderinterruptionstatuses';
export const MODAL_ORDER_SETTLEMENT_PHASES_TABLE = 'dictionary-table&ordersettlementstatuses';
export const MODAL_REPORT_STATUSES_TABLE = 'dictionary-table&reportStatuses';
export const MODAL_TRANSPORT_ORDER_ADDITIONAL_STATUSES_TABLE =
  'dictionary-table&transportOrderAdditionalStatuses';
export const MODAL_STORAGE_TYPES_TABLE = 'dictionary-table&storagetype';
export const MODAL_DISMANTLING_FAILURE_REASONS_TABLE = 'dictionary-table&dismantlingfailurereasons';
export const MODAL_DEM_ELEM_TYPES_TABLE = 'dictionary-table&demElemTypes';
export const MODAL_ACTIONS_REPORT_STATUSES_TABLE = 'dictionary-table&actionsReportStatuses';
