<script setup>
import { ref, computed } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';
import { RADO_LOGO_SIZE_LOGIN_PAGE } from '../data/constants/appConstants';
import { useSideMenuStore } from '../stores/sideMenu';

const username = ref('');
const password = ref('');
const isLoading = ref(false);
const authStore = useAuthStore();
const sideMenuStore = useSideMenuStore();
const router = useRouter();

const formInfo = computed(() => authStore.formInfo);

function logIn(e) {
  e.preventDefault();
  isLoading.value = true;

  authStore
    .requestAuth({ username: username.value, password: password.value })
    .then(() => {
      sideMenuStore.resetState();
      //redirected to orders after logging in
      router.push('/orders');
      authStore.resetFormError();
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      isLoading.value = false;
    });
}

function onForgotPasswordClicked() {
  router.push({ name: 'forgotPassword' });
}
</script>

<template>
  <div
    class="middle-box text-center loginscreen animated fadeIn"
    style="width: 100vh; max-width: fit-content"
  >
    <div>
      <img
        src="/static/img/rado_logo.png"
        alt="RADO - logo"
        :width="RADO_LOGO_SIZE_LOGIN_PAGE[0]"
        :height="RADO_LOGO_SIZE_LOGIN_PAGE[1]"
      />

      <form class="m-t" style="text-align: center; width: 300px; margin: auto" @submit="logIn">
        <div class="form-group">
          <input
            v-model="username"
            type="email"
            class="form-control"
            placeholder="Wpisz email"
            required
            minlength="1"
            maxlength="100"
          />
        </div>
        <div class="form-group">
          <input
            v-model="password"
            type="password"
            class="form-control"
            placeholder="Wpisz hasło"
            required
            minlength="1"
            maxlength="100"
          />
        </div>

        <div v-if="formInfo && formInfo !== ''">
          <p style="color: red">{{ formInfo }}</p>
        </div>

        <div v-if="isLoading" class="sk-spinner sk-spinner-circle mb-3 mt-3">
          <div class="sk-circle1 sk-circle"></div>
          <div class="sk-circle2 sk-circle"></div>
          <div class="sk-circle3 sk-circle"></div>
          <div class="sk-circle4 sk-circle"></div>
          <div class="sk-circle5 sk-circle"></div>
          <div class="sk-circle6 sk-circle"></div>
          <div class="sk-circle7 sk-circle"></div>
          <div class="sk-circle8 sk-circle"></div>
          <div class="sk-circle9 sk-circle"></div>
          <div class="sk-circle10 sk-circle"></div>
          <div class="sk-circle11 sk-circle"></div>
          <div class="sk-circle12 sk-circle"></div>
        </div>

        <button type="submit" class="btn btn-primary block full-width m-b">Zaloguj</button>

        <a @click="onForgotPasswordClicked"><small>Zapomniałeś hasło?</small></a>
      </form>
      <p class="m-t">
        <small>DKK Development &copy; 2023</small>
      </p>
    </div>
  </div>
</template>

<style scoped></style>
