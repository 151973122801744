import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { isNotEmptyArray } from './utilsHelper';
import { createToaster } from '@meforma/vue-toaster';

const FIRST_ROW_HEIGHT = 60;
const FIRST_ROW_COLOR = 'C0DAEC';
const GREY_COLUMNS_COLOR = 'DCDDDC';

export function generateActionsReport(reportData, worksheetName = 'Worksheet') {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(worksheetName);
  const headers = reportData.headers;

  if (!isNotEmptyArray(headers)) {
    const toaster = createToaster({ position: 'top-right' });
    toaster.show('Brak danych, nie można wygenerować raportu.', {
      duration: 3000,
      type: 'warning'
    });

    return;
  }

  worksheet.addRow(headers);

  for (let i = 0; i < reportData.rows.length; i++) {
    worksheet.addRow(reportData.rows[i]);
  }

  // Format cells
  const greyColumnFill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: GREY_COLUMNS_COLOR }
  };

  worksheet.getColumn(7).fill = greyColumnFill;
  worksheet.getColumn(8).fill = greyColumnFill;
  worksheet.getColumn(9).fill = greyColumnFill;

  worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: FIRST_ROW_COLOR }
  };
  worksheet.getRow(1).font = { name: 'Calibri', size: 12, bold: true };
  worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

  if (FIRST_ROW_HEIGHT != undefined) {
    worksheet.getRow(1).height = FIRST_ROW_HEIGHT;
  }

  worksheet.columns.forEach((column) => {
    column.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
  });

  worksheet.getColumn(1).width = 20;
  worksheet.getColumn(2).width = 20;
  worksheet.getColumn(3).width = 20;
  worksheet.getColumn(4).width = 40;
  worksheet.getColumn(5).width = 15;
  worksheet.getColumn(6).width = 15;
  worksheet.getColumn(7).width = 10;
  worksheet.getColumn(8).width = 30;
  worksheet.getColumn(9).width = 40;
  worksheet.getColumn(10).width = 25;

  for (let i = 2; i <= worksheet.rowCount; i++) {
    worksheet.getRow(i).alignment = { wrapText: true };
  }

  // Export xlsx
  workbook.xlsx
    .writeBuffer()
    .then((buffer) =>
      saveAs(
        new Blob([buffer]),
        `Raport z rejestru akcji - ${new Date().toLocaleDateString()}.xlsx`
      )
    )
    .catch((err) => console.log('generateActionsReport(): Error writing excel export.', err));
}
