<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import {
  APP_PRIMARY_COLOR,
  REPORT_STORAGE_PLACE_TYPE_FOR_MAP,
  OSD_TYPES_FOR_MAP_IDS,
  OSD_DEM_ELEM_TYPE_ID,
  POLE_DEM_ELEM_TYPE_ID,
  DISMANTLING_FAILURE_TYPE_FOR_MAP,
  DISMANTLING_FAILURE_TYPE_FOR_POINTS
} from '../../data/constants/appConstants';
import { useFilesHandler } from '../../composables/filesHandler';
import { createToaster } from '@meforma/vue-toaster';
import { TOASTER_SERVER_ERROR_MSG } from '../../data/constants/toasterMessagesConstants';
import ClusterMap from '@/components/map/ClusterMap.vue';
import DropArea from '../utils/DropArea.vue';
import FilesTable from '../files/FilesTable.vue';
import FormErrorPanel from '../utils/FormErrorPanel.vue';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { useMapLogic } from '../../composables/mapLogic';
import {
  getDemPointsForMapMarkers,
  getActionsWithNoDemPointForMapMarkers
} from '../../helpers/actionMarkersHelper';
import ActionEditForOSDStep2 from './ActionEditForOSDStep2.vue';
import { useAuthStore } from '../../stores/auth';
import { fetchDictionaryData } from '../../helpers/dictionariesFetchHelper';

const RepositoryActions = RepositoryFactory.get('actions');

const FILES_INPUT_ID = 'fileInputFieldActionEdit';

const props = defineProps({
  orderId: { type: Number, default: null },
  actionId: { type: Number, default: null },
  demPointsForMap: { type: Array, default: null },
  actionsWithNoDemPointForMap: { type: Array, default: null }
});
const emit = defineEmits(['succeeded', 'modalTitleChanged']);
const toaster = createToaster({ position: 'top-right', duration: 3000 });
const filesHandler = useFilesHandler(true, FILES_INPUT_ID);
const componentReady = ref(false);
const osdReleaseTypes = ref(null);
const osdElementLinkTypes = ref(null);
const osdData = ref({
  pickedOsdReleaseTypeId: null,
  pickedOsdElementLinkTypeId: null,
  copperCableAmount: null,
  opticalCableAmount: null
});
const actionMarker = ref(null);
const step = ref(1);
const authStore = useAuthStore();
const userOrders = ref(null);
const typesForPoints = ref(null);
const typesForMap = ref(null);
const demElemTypes = ref(null);
const failureReasons = ref(null);

// form data
const actionData = ref(null);
const markerPosition = ref(null);
const filesForTable = ref([]);
const formErrors = ref([]);
const { clusterMap } = useMapLogic();
const pickedUserOrderId = ref(null);
const pickedTypeForPointsId = ref(null);
const pickedTypeForMapId = ref(null);
const pickedDemElemTypeId = ref(null);
const pickedFailureReasonId = ref(null);

setTimeout(() => {
  componentReady.value = true;
}, 500);

const pickedActionTypeId = computed(() => {
  return actionDirectlyOnTheMap.value ? pickedTypeForMapId.value : pickedTypeForPointsId.value;
});

const showStoragePlaceFormSection = computed(() => {
  return (
    actionData.value != null &&
    !actionData.value.dem_point &&
    actionData.value.action_type?.id === REPORT_STORAGE_PLACE_TYPE_FOR_MAP
  );
});

const showOSDFormSection = computed(() => {
  return (
    actionData.value != null &&
    OSD_TYPES_FOR_MAP_IDS.includes(actionData.value.action_type?.id) &&
    actionData.value.dem_elem_type?.id === OSD_DEM_ELEM_TYPE_ID
  );
});

const canChangePosition = computed(() => {
  return (
    actionData.value != null &&
    actionData.value.dem_point == null &&
    actionData.value.geotimestamp != null
  );
});

const isLoadingMap = computed(() => {
  if (!componentReady.value) return true;

  return markerPosition.value == null;
});

const isLoadingForm = computed(() => {
  if (actionData.value == null) return true;

  if (
    showOSDFormSection.value &&
    (!isNotEmptyArray(osdReleaseTypes.value?.data) ||
      !isNotEmptyArray(osdElementLinkTypes.value?.data))
  )
    return true;

  if (!isNotEmptyArray(userOrders.value?.data)) return true;
  if (!isNotEmptyArray(failureReasons.value?.data)) return true;

  return false;
});

const demPointsForOSDMap = computed(() => {
  if (!isNotEmptyArray(props.demPointsForMap)) return [];

  return props.demPointsForMap.filter(
    (demPoint) => demPoint.dem_elem_type?.id === POLE_DEM_ELEM_TYPE_ID
  );
});

const modalTitle = computed(() => {
  if (step.value === 2) return 'Zdefiniuj powiązania';
  if (showOSDFormSection.value) return 'Edytuj akcję OSD';

  return 'Edytuj akcję';
});

const actionDirectlyOnTheMap = computed(() => {
  if (actionData.value == null) return undefined;

  return actionData.value.dem_point == null;
});

const isDismantlingFailureActionTypeSet = computed(() => {
  return actionDirectlyOnTheMap.value
    ? pickedActionTypeId.value === DISMANTLING_FAILURE_TYPE_FOR_MAP
    : pickedActionTypeId.value === DISMANTLING_FAILURE_TYPE_FOR_POINTS;
});

function isDemElemTypeDisabled(demElemType) {
  if (pickedDemElemTypeId.value == null) return true;
  if (demElemType?.id == null) return true;

  if (pickedDemElemTypeId.value === OSD_DEM_ELEM_TYPE_ID) {
    return demElemType.id !== pickedDemElemTypeId.value;
  } else {
    return demElemType.id === OSD_DEM_ELEM_TYPE_ID;
  }
}

function isActionTypeForMapDisabled(actionType) {
  if (pickedTypeForMapId.value == null) return true;
  if (actionType?.id == null) return true;

  if (pickedTypeForMapId.value === REPORT_STORAGE_PLACE_TYPE_FOR_MAP) {
    return actionType.id !== pickedTypeForMapId.value;
  } else {
    return actionType.id === REPORT_STORAGE_PLACE_TYPE_FOR_MAP;
  }
}

watch(isLoadingMap, (newValue) => {
  if (!newValue) {
    prepareMarkerForMap();
  }
});

watch(filesHandler.filesRef, () => {
  recomputeFilesForTable();
});

watch(modalTitle, (newValue) => {
  emit('modalTitleChanged', newValue);
});

function recomputeFilesForTable() {
  filesForTable.value = filesForTable.value.concat(filesHandler.filesRef.value);

  for (let i = 0; i < filesForTable.value.length; i++) {
    filesForTable.value[i].id = i;
  }
}

function prepareMarkerForMap() {
  const nonDraggableMarkers = getAllNonDraggableMarkers();
  const markerToDeleteId = nonDraggableMarkers.findIndex(
    (marker) => marker.markerId === `action_${props.actionId}`
  );

  if (markerToDeleteId >= 0) {
    nonDraggableMarkers.splice(markerToDeleteId, 1);
  }

  actionMarker.value = {
    lat: markerPosition.value.lat,
    lng: markerPosition.value.lon,
    draggable: canChangePosition.value,
    selected: false,
    selectable: false,
    zIndexOffset: 1000
  };

  const markers = [actionMarker.value].concat(nonDraggableMarkers);
  clusterMap.value.initializeMap({ markersData: markers });
}

function getAllNonDraggableMarkers() {
  const demPointsForMapMarkers = getDemPointsForMapMarkers(
    props.demPointsForMap,
    [],
    [],
    [],
    false,
    false,
    true,
    'half-transparent',
    true,
    authStore,
    false,
    null
  );

  const actionsWithNoDemPointForMapMarkers = getActionsWithNoDemPointForMapMarkers(
    props.actionsWithNoDemPointForMap,
    [],
    [],
    [],
    false,
    'half-transparent',
    authStore,
    false,
    null
  );

  return demPointsForMapMarkers.concat(actionsWithNoDemPointForMapMarkers);
}

function onMarkerDragged(marker) {
  markerPosition.value = { lat: marker.lat, lon: marker.lng };
}

function onFileDeleted(id) {
  filesForTable.value = filesForTable.value.filter((x) => x.id !== id);
}

async function fetchDataToEdit() {
  actionData.value = await RepositoryActions.getActionDataToEdit(props.actionId);

  if (isNotEmptyArray(actionData.value.storage)) {
    actionData.value.storage.forEach((storageElem) => {
      if (storageElem.id == null) {
        storageElem.number = 0;
      }
    });
  }

  if (actionData.value.priority != null) {
    actionData.value.priority = Boolean(Number(actionData.value.priority));
  }

  markerPosition.value = {
    lat: actionData.value?.dem_point?.lat ?? actionData.value?.geotimestamp?.lat,
    lon: actionData.value?.dem_point?.lon ?? actionData.value?.geotimestamp?.lon
  };

  if (showOSDFormSection.value) {
    osdReleaseTypes.value = await fetchDictionaryData('osdrelease');
    osdElementLinkTypes.value = await fetchDictionaryData('osdelementlink');

    osdData.value.pickedOsdReleaseTypeId = actionData.value?.osd_detail?.osd_release?.id;
    osdData.value.pickedOsdElementLinkTypeId = actionData.value?.osd_detail?.osd_element_link?.id;
    osdData.value.copperCableAmount = actionData.value?.osd_detail?.copper_cable;
    osdData.value.opticalCableAmount = actionData.value?.osd_detail?.optical_cable;
  }

  if (actionDirectlyOnTheMap.value) {
    typesForMap.value = await fetchDictionaryData('actiontype/typesForMap');
    pickedTypeForMapId.value = actionData.value.action_type.id;

    demElemTypes.value = await fetchDictionaryData('demelemtype');
    pickedDemElemTypeId.value = actionData.value.dem_elem_type?.id;
  } else {
    typesForPoints.value = await fetchDictionaryData('actiontype/typesForPoints');
    pickedTypeForPointsId.value = actionData.value.action_type.id;
  }

  if (isDismantlingFailureActionTypeSet.value) {
    pickedFailureReasonId.value = actionData.value.failure_reason?.id;
  }

  pickedUserOrderId.value = actionData.value.id_user_order;
}

function submitForm(e) {
  e.preventDefault();

  if (showOSDFormSection.value) {
    step.value = 2;
  } else {
    updateAction();
  }
}

async function updateAction(osdStringified = null) {
  const updatedAction = Object.assign({}, actionData.value);

  updatedAction.files = isNotEmptyArray(filesForTable.value)
    ? filesForTable.value.map((photo) => photo.file)
    : [];

  if (canChangePosition.value) {
    updatedAction.geotimestamp.lat = markerPosition.value.lat;
    updatedAction.geotimestamp.lon = markerPosition.value.lon;
  }

  if (osdStringified != null) {
    updatedAction.osdStringified = osdStringified;
  }

  if (showStoragePlaceFormSection.value && updatedAction.priority == null) {
    updatedAction.priority = false;
  }

  updatedAction.userOrderId = pickedUserOrderId.value;

  var response = await RepositoryActions.updateAction(
    updatedAction,
    pickedActionTypeId.value,
    pickedDemElemTypeId.value,
    pickedFailureReasonId.value
  );
  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error' });
  } else {
    if (response) {
      formErrors.value = response;
    } else {
      toaster.show('Akcja została zaktualizowana', { type: 'success' });
      emit('succeeded');
    }
  }
}

function updateOSDAction(demPoints) {
  const osd = {
    id_osd_release: osdData.value.pickedOsdReleaseTypeId,
    id_osd_element_link: osdData.value.pickedOsdElementLinkTypeId,
    optical_cable: osdData.value.opticalCableAmount,
    copper_cable: osdData.value.copperCableAmount,
    demPoints: demPoints
  };

  const osdStringified = JSON.stringify(osd);
  updateAction(osdStringified);
}

onMounted(async () => {
  fetchDataToEdit();
  userOrders.value = await fetchDictionaryData(`order/${props.orderId}/userOrders`);
  failureReasons.value = await fetchDictionaryData('failureactionreason');
});
</script>

<template>
  <div v-show="step === 1" class="row">
    <div class="ibox col-lg-6 pr-1">
      <div class="ibox-content" :class="{ 'sk-loading': isLoadingMap }" style="border-style: none">
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <ClusterMap
          ref="clusterMap"
          height="700px"
          :showZeroPositions="false"
          :disableClusteringAtZoomOverride="19"
          :maxClusterRadiusOverride="1"
          @markerDragged="onMarkerDragged"
        />
      </div>
    </div>

    <div class="ibox col-lg-6 pl-1">
      <div
        class="ibox-content profile-content pl-4"
        :class="{ 'sk-loading': isLoadingForm }"
        style="border-style: none"
      >
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <FormErrorPanel :errors="formErrors" />

        <form @submit="submitForm">
          <div class="row" :style="`border-bottom: 1px dashed #${APP_PRIMARY_COLOR}`">
            <div class="col-6">
              <h4>Edytuj akcję</h4>

              <div v-if="isNotEmptyArray(userOrders?.data)" class="form-group mt-2">
                <label>Pracownik</label>
                <select class="form-control m-b" v-model="pickedUserOrderId" required>
                  <option disabled :value="null" label="Wybierz pracownika" />
                  <option
                    v-for="userOrder in userOrders.data"
                    :key="userOrder.id"
                    :value="userOrder.id"
                  >
                    {{ userOrder.user.firstName + ' ' + userOrder.user.lastName }}
                  </option>
                </select>
              </div>

              <div
                v-if="actionDirectlyOnTheMap && isNotEmptyArray(typesForMap?.data)"
                class="form-group mt-2"
              >
                <label>Typ akcji</label>
                <select class="form-control m-b" v-model="pickedTypeForMapId" required>
                  <option disabled :value="null" label="Wybierz typ akcji" />
                  <option
                    v-for="typeForMap in typesForMap.data"
                    :key="typeForMap.id"
                    :value="typeForMap.id"
                    :disabled="isActionTypeForMapDisabled(typeForMap)"
                    :style="{
                      color: isActionTypeForMapDisabled(typeForMap) ? '#D3D3D3' : 'black'
                    }"
                  >
                    {{ typeForMap.name }}
                  </option>
                </select>
              </div>

              <div
                v-if="actionDirectlyOnTheMap === false && isNotEmptyArray(typesForPoints?.data)"
                class="form-group mt-2"
              >
                <label>Typ akcji</label>
                <select class="form-control m-b" v-model="pickedTypeForPointsId" required>
                  <option disabled :value="null" label="Wybierz typ akcji" />
                  <option
                    v-for="typeForPoints in typesForPoints.data"
                    :key="typeForPoints.id"
                    :value="typeForPoints.id"
                  >
                    {{ typeForPoints.name }}
                  </option>
                </select>
              </div>

              <div
                v-if="isDismantlingFailureActionTypeSet && isNotEmptyArray(failureReasons?.data)"
                class="form-group mt-2"
              >
                <label>Powód niepowodzenia</label>
                <select class="form-control m-b" v-model="pickedFailureReasonId" required>
                  <option disabled :value="null" label="Wybierz powód niepowodzenia" />
                  <option
                    v-for="failureReason in failureReasons.data"
                    :key="failureReason.id"
                    :value="failureReason.id"
                  >
                    {{ failureReason.name }}
                  </option>
                </select>
              </div>

              <div
                v-if="actionData?.dem_elem_type && actionDirectlyOnTheMap === false"
                class="form-group mt-2"
              >
                <label>Typ elementu</label>
                <select class="form-control m-b" disabled>
                  <option :label="actionData.dem_elem_type.name" />
                </select>
              </div>

              <div
                v-else-if="actionDirectlyOnTheMap && isNotEmptyArray(demElemTypes?.data)"
                class="form-group mt-2"
              >
                <label>Typ elementu</label>
                <select
                  class="form-control m-b"
                  v-model="pickedDemElemTypeId"
                  :disabled="!actionDirectlyOnTheMap"
                  required
                >
                  <option disabled :value="null" label="Wybierz typ elementu" />
                  <option
                    v-for="demElemType in demElemTypes.data"
                    :key="demElemType.id"
                    :value="demElemType.id"
                    :disabled="isDemElemTypeDisabled(demElemType)"
                    :style="{ color: isDemElemTypeDisabled(demElemType) ? '#D3D3D3' : 'black' }"
                  >
                    {{ demElemType.name }}
                  </option>
                </select>
              </div>

              <div v-if="actionData" class="form-group">
                <label>Notatka</label>
                <input
                  :required="isDismantlingFailureActionTypeSet"
                  type="text"
                  placeholder="Wpisz notatkę"
                  v-model="actionData.note"
                  class="form-control"
                  maxlength="500"
                />
              </div>
            </div>

            <div v-if="showStoragePlaceFormSection" class="col-6">
              <h4>Miejsce składowania</h4>
              <div
                v-for="storageType in actionData.storage"
                :key="storageType.id_storage_type"
                class="form-group"
              >
                <label>{{ storageType.name }}</label>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  placeholder="-"
                  v-model="storageType.number"
                  class="form-control"
                  min="0"
                  max="999999999"
                  required
                />
              </div>

              <div class="form-group">
                <label>Priorytet</label>
                <div class="switch">
                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      id="prioritySwitchId"
                      v-model="actionData.priority"
                    />
                    <label class="onoffswitch-label" for="prioritySwitchId">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="showOSDFormSection" class="col-6">
              <h4>Demontaż OSD</h4>

              <div class="form-group mt-2">
                <label>Uwolnienie</label>
                <select class="form-control m-b" v-model="osdData.pickedOsdReleaseTypeId" required>
                  <option disabled :value="null" label="Wybierz typ uwolnienia" />
                  <option
                    v-for="osdReleaseType in osdReleaseTypes?.data"
                    :key="osdReleaseType.id"
                    :value="osdReleaseType.id"
                  >
                    {{ osdReleaseType.name }}
                  </option>
                </select>
              </div>

              <div class="form-group mt-2">
                <label>Powiązanie z elementem</label>
                <select
                  class="form-control m-b"
                  v-model="osdData.pickedOsdElementLinkTypeId"
                  required
                >
                  <option disabled :value="null" label="Wybierz typ powiązania" />
                  <option
                    v-for="osdElementLinkType in osdElementLinkTypes?.data"
                    :key="osdElementLinkType.id"
                    :value="osdElementLinkType.id"
                  >
                    {{ osdElementLinkType.name }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label>Liczba kabli miedzianych</label>
                <input
                  type="number"
                  placeholder="Wpisz liczbę kabli miedzianych"
                  v-model="osdData.copperCableAmount"
                  class="form-control"
                  required
                  min="0"
                  max="999999999"
                />
              </div>

              <div class="form-group">
                <label>Liczba kabli światłowodowych</label>
                <input
                  type="number"
                  placeholder="Wpisz liczbę kabli światłowodowych"
                  v-model="osdData.opticalCableAmount"
                  class="form-control"
                  required
                  min="0"
                  max="999999999"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <FilesTable :filesArray="filesForTable" @fileDeleted="onFileDeleted" />
            </div>

            <div class="col-12">
              <h5>Dodaj zdjęcia</h5>

              <DropArea
                id="dropAreaFiles"
                class="dropArea"
                caption="Upuść pliki tutaj albo kliknij 'Wybierz pliki' poniżej"
                :fileInputId="FILES_INPUT_ID"
                @onFileChanged="filesHandler.onDropAreaFilesChanged($event)"
              />
              <input
                :id="FILES_INPUT_ID"
                name="file"
                type="file"
                class="mt-2"
                multiple
                @change="filesHandler.onFilesChanged($event)"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button class="btn btn-primary mt-3 float-right" type="submit">
                {{ `${showOSDFormSection ? 'Krok 2. Zdefiniuj powiązania' : 'Prześlij dane'}` }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div v-if="step === 2">
    <ActionEditForOSDStep2
      :dem-points-of-type-pole-for-map="demPointsForOSDMap"
      :location="markerPosition"
      :initiallySelectedPoles="actionData?.osd_detail?.osd_poles"
      @go-to-the-first-step="step = 1"
      @updateOSDAction="updateOSDAction"
    />
  </div>
</template>

<style scoped></style>
