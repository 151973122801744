<script setup>
import { ref, computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { navItems } from '@/helpers/navigationPanelHelper.js';
import { useFilesHandler } from '@/composables/filesHandler.js';
import FormErrorPanel from '@/components/utils/FormErrorPanel.vue';
import {
  MODAL_ORDER_RANGES_TABLE,
  MODAL_UNITS_OF_MEASURE_TABLE
} from '../../data/constants/modalConstants.js';
import ModalInputField from '@/components/utils/ModalInputField.vue';
import ModalTable from '@/components/utils/ModalTable.vue';
import { useNavigationPanelStore } from '@/stores/navigationPanel';
import { createToaster } from '@meforma/vue-toaster';
import DropArea from '@/components/utils/DropArea.vue';
import FilesTable from '../files/FilesTable.vue';
import { TOASTER_SERVER_ERROR_MSG } from '../../data/constants/toasterMessagesConstants';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { NUMBER_UNIT_TYPE_ID, KG_UNIT_TYPE_ID } from '../../data/constants/appConstants';

const FILES_INPUT_ID = 'fileInputField654';

const RepositoryOrders = RepositoryFactory.get('orders');
const RepositoryAddresses = RepositoryFactory.get('addresses');
const RepositoryDictionaries = RepositoryFactory.get('dictionaries');

const modalTable = ref();
const router = useRouter();
const route = useRoute();
const formErrors = ref([]);
const isLoading = ref(false);
const isLoadingUnitTypes = ref(false);
const address = ref({
  id: null,
  address: null,
  order_range: null,
  unit_of_measure: null,
  createStoragePlace: null,
  owner_phone: null,
  quantity: null,
  description: null
});
const rangeDisplayName = ref(null);
const unitDisplayName = ref(null);
const toaster = createToaster({ position: 'top-right', duration: 3000 });
const { filesRef, onFilesChanged, onDropAreaFilesChanged } = useFilesHandler(true, FILES_INPUT_ID);
const filesForTable = ref([]);
const orderTypeId = ref(null);

const addMode = computed(() => {
  return isNaN(parseInt(route.params.addressId));
});

const idForEdit = computed(() => {
  return parseInt(route.params.addressId);
});

const orderId = computed(() => {
  const parsedId = parseInt(route.params?.orderId);
  return isNaN(parsedId) ? null : parsedId;
});

const orderOfTypeAdditional = computed(() => {
  return orderTypeId.value === 4;
});

watch(filesRef, () => {
  recomputeFilesForTable();
});

watch(orderTypeId, () => {
  if (addMode.value) {
    fetchUnitTypes();
  }
});

function recomputeFilesForTable() {
  filesForTable.value = filesForTable.value.concat(filesRef.value);

  for (let i = 0; i < filesForTable.value.length; i++) {
    filesForTable.value[i].id = i;
  }
}

function submitForm(e) {
  e.preventDefault();

  if (addMode.value) {
    sendAddRequest();
  } else {
    sendEditRequest();
  }
}

async function getOrderAddressDataToEdit() {
  isLoading.value = true;

  address.value = await RepositoryAddresses.getAddressToEdit(idForEdit.value);
  rangeDisplayName.value = address.value.order_range?.name;
  unitDisplayName.value = address.value.unit_of_measure?.name;

  isLoading.value = false;
}

async function sendAddRequest() {
  isLoading.value = true;

  const files = isNotEmptyArray(filesForTable.value)
    ? filesForTable.value.map((photo) => photo.file)
    : [];

  address.value.files = files;

  var response = await RepositoryOrders.createAddressForOrder(orderId.value, address.value);

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error' });
  } else {
    if (response) {
      if (response.data?.id != null) {
        toaster.show('Adres został utworzony.', { type: 'success' });
        router.push({ name: 'orderAddEdit', params: { id: orderId.value } });
      } else {
        formErrors.value = response?.message;
      }
    }
  }

  isLoading.value = false;
}

async function sendEditRequest() {
  isLoading.value = true;

  if (isNotEmptyArray(filesForTable.value)) {
    address.value.files = filesForTable.value.map((photo) => photo.file);
  }

  var response = await RepositoryAddresses.updateAddress(address.value);

  if (response?.serverError) toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error' });
  else {
    if (response) {
      formErrors.value = response;
    } else {
      toaster.show('Adres został zaktualizowany.', { type: 'success' });
      router.push({ name: 'orderAddEdit', params: { id: orderId.value } });
    }
  }

  isLoading.value = false;
}

function onModalCompleted(data) {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_ORDER_RANGES_TABLE:
      address.value.order_range = data ?? null;
      rangeDisplayName.value = data?.name ?? null;
      break;
    case MODAL_UNITS_OF_MEASURE_TABLE:
      address.value.unit_of_measure = data ?? null;
      unitDisplayName.value = data?.name ?? null;
      break;
  }
}

function onModalRejected() {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_ORDER_RANGES_TABLE:
      address.value.order_range = null;
      rangeDisplayName.value = 'Wybierz zakres';
      break;
    case MODAL_UNITS_OF_MEASURE_TABLE:
      address.value.unit_of_measure = null;
      unitDisplayName.value = 'Wybierz jednostkę';
      break;
  }
}

function onFileDeleted(id) {
  filesForTable.value = filesForTable.value.filter((x) => x.id !== id);
}

async function fetchOrderTypeData() {
  const order = await RepositoryOrders.getOrderDetailsGeneral(orderId.value); //TODO ASK modify later
  orderTypeId.value = order?.order_type?.id;
}

async function fetchUnitTypes() {
  isLoadingUnitTypes.value = true;

  RepositoryDictionaries.getDictionaryElementsObject(1, 100, '', '', '', 'unitofmeasure')
    .then((data) => {
      const searchedUnitTypeId = orderOfTypeAdditional.value
        ? KG_UNIT_TYPE_ID
        : NUMBER_UNIT_TYPE_ID;
      const unit = data?.data?.find((unit) => unit.id === searchedUnitTypeId);
      address.value.unit_of_measure = unit ?? null;
      unitDisplayName.value = unit?.name ?? null;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoadingUnitTypes.value = false;
    });
}

// created
useNavigationPanelStore().setNavigationPanelItems(
  addMode.value ? navItems.ORDER_ADDRESS_ADD : navItems.ORDER_ADDRESS_EDIT
);

fetchOrderTypeData();

if (!addMode.value) {
  getOrderAddressDataToEdit();
}
</script>

<template>
  <div>
    <FormErrorPanel :errors="formErrors" />

    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5 v-if="addMode">Dodaj adres</h5>
            <h5 v-else>Edytuj adres</h5>
          </div>
          <div class="ibox-content" :class="{ 'sk-loading': isLoading || isLoadingUnitTypes }">
            <div class="sk-spinner sk-spinner-three-bounce">
              <div class="sk-bounce1"></div>
              <div class="sk-bounce2"></div>
              <div class="sk-bounce3"></div>
            </div>

            <form @submit="submitForm">
              <div class="form-group">
                <label>Adres</label>
                <input
                  type="text"
                  placeholder="Wpisz adres"
                  v-model="address.address"
                  class="form-control"
                  required
                  minlength="1"
                  maxlength="100"
                />
              </div>

              <ModalInputField
                title="Zakres"
                :displayText="rangeDisplayName"
                nullText="Wybierz zakres"
                :required="true"
                @showModal="modalTable.showModal(MODAL_ORDER_RANGES_TABLE)"
              />

              <ModalInputField
                title="Jednostka"
                :displayText="unitDisplayName"
                nullText="Wybierz jednostkę"
                :required="true"
                @showModal="modalTable.showModal(MODAL_UNITS_OF_MEASURE_TABLE)"
              />

              <div v-if="orderOfTypeAdditional" class="form-group">
                <label>Stwórz miejsce składowania</label>
                <div class="switch">
                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      id="example1"
                      v-model="address.createStoragePlace"
                    />
                    <label class="onoffswitch-label" for="example1">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label>Telefon właściciela</label>
                <input
                  type="tel"
                  placeholder="Wpisz numer telefonu"
                  v-model="address.owner_phone"
                  class="form-control"
                  minlength="1"
                  maxlength="30"
                />
              </div>

              <div class="form-group">
                <label>Ilość</label>
                <input
                  type="number"
                  placeholder="Wpisz ilość"
                  v-model="address.quantity"
                  class="form-control"
                  required
                  min="1"
                  max="999999999"
                />
              </div>

              <div class="form-group">
                <label>Opis</label>
                <input
                  type="text"
                  placeholder="Wpisz opis"
                  v-model="address.description"
                  class="form-control"
                  maxlength="500"
                />
              </div>

              <div class="row">
                <div class="col-12 mb-3">
                  <FilesTable :filesArray="filesForTable" @fileDeleted="onFileDeleted" />
                </div>

                <div class="col-12">
                  <h5>Wczytaj pliki</h5>

                  <DropArea
                    id="dropArea2"
                    class="dropArea"
                    caption="Upuść plik tutaj albo kliknij 'Wybierz pliki' poniżej"
                    :fileInputId="FILES_INPUT_ID"
                    @onFileChanged="onDropAreaFilesChanged($event)"
                  />
                  <input
                    :id="FILES_INPUT_ID"
                    name="file"
                    type="file"
                    class="mt-2"
                    multiple
                    @change="onFilesChanged($event)"
                  />
                </div>
              </div>

              <br />
              <div class="row">
                <div class="col-12">
                  <button class="float-right btn btn-sm btn-primary m-t-n-xs" type="submit">
                    <strong v-if="addMode">Dodaj</strong>
                    <strong v-else>Zapisz</strong>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <ModalTable
      ref="modalTable"
      @onModalCompleted="onModalCompleted"
      @onModalRejected="onModalRejected"
    ></ModalTable>
  </div>
</template>

<style></style>
